// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

require("@rails/ujs").start()
require("@rails/activestorage").start()
require("channels")
require("dropzone")

//var Turbolinks = require("turbolinks")
//Turbolinks.start()

import 'bootstrap'
import 'bootstrap/js/dist/tooltip'
import 'jquery.metisMenu'
import 'jquery.slimscroll.min'
import 'jquery-mask-plugin'
import 'icheck.min'
import 'bootstrap-datepicker.js'
import '@selectize/selectize'
import 'stylesheets/selectize.bootstrap4.css'
import 'inspinia'
import 'bootstrap-toggle'

import '../stylesheets/application'


import '@fortawesome/fontawesome-free/css/all.css'

import 'main'
import 'rollbar_load'


//require( 'datatables.net-dt' );
//require( 'datatables.net-buttons-bs4' )( window, $ );
//require( 'datatables.net-buttons/js/buttons.print.js' )( window, $ );
//require( 'datatables.net-responsive-bs4' )( window, $ );




// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
// const images = require.context('../images', true)
// const imagePath = (name) => images(name, true)
